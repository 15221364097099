 
 
 export const routeConstants = {
     all: '*',
     home: '/',
     login: 'log-in',
     resetPassword: 'reset-password',
     studentLogin: 'student-login',
     apply: 'apply',
     schoolApplication: 'school-application',
     vendorApplication: 'vendor-application',
     dashboard: 'dashboard',
     studentDisbursement: 'disbursement',
     studentRepayment: 'repayment',
     fundManagement: 'fund-management',
     studentRecords: 'student-records',
     studentVerification: 'student-verification',
     pendingStudents: 'pending-students',
     auditLog: 'audit-log',
     consumers: 'consumers',
     consumerDashboard: 'consumer-dashboard',
     institutions: 'institutions',
     institutionDashboard: 'institution-dashboard',
     beneficiaries: 'beneficiaries',
     beneficiaryDashboard: 'beneficiary-dashboard',


     register: 'register',
     terms: 'terms',
     faq: 'faq',
     unauthorized: 'unauthorized',
     deactivated: 'deactivated',

     admin: 'admin',
    financial_institution:  'financial_institution',
    manager: 'manager',
     school_manager: 'school-manager',
     school_official: 'school-official',
     finance_manager: 'finance-manager',
     student: 'student',

     userManagement: 'user-management',
     profile: 'profile',
     appSettings: 'app-settings',
     settings: 'settings',

     userLevels: {
        admin: 'admin',
        financial_admin:  'financial_admin',
        manager: 'manager',
        school_manager: 'school_manager',
        school_official: 'school_official',
        finance_manager: 'finance_manager',
        student: 'student',
     },
     userLevelIds: {
        admin: 1,
        school_official: 5,
        school_manager: 2,
        agent: 4,
     },
     userLevelKeys: [
        {
            id: 1,
            name: "admin",
        },
        {
            id: 2,
            name: "school_manager",
        },
        {
            id: 4,
            name: "manager",
        },
        {
            id: 5,
            name: "school_official",
        },
        {
            id: 6,
            name: "finance_manager",
         },
         {
             id: 6,
             name: "student",
         }
    ],
 };
